import React, { useState, useEffect } from "react";

import { Header, Sidebar, ContentItem, Footer } from "../../parts";

import { attachLoginStyle, resetStyle } from "../../libs/helper";

import Loader from "react-loader-spinner";

const Login = (props) => {
  const { callAction, call_action, match } = props;

  const [message, setMessage] = useState(null);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState(null);
  const [password, setPassword] = useState(null);

  const login = () => {
    callAction(call_action, "login", {
      endpoint: `/login`,
      scenario: "post",
      data: {
        username,
        password,
      },
    });
    setLoading(true);
  };
  const handleLogin = (payload) => {
    console.log("payload", payload);
    if (!payload) return;
    if (payload.status === 0) {
      setMessage("Username/Password yang anda masukkan salah!");
      localStorage.setItem("is_login", 0);
    } else {
      setMessage("Mohon tunggu sebentar...");
      const { access_token, mdid, daerah, user } = payload;
      localStorage.setItem("token", access_token);
      localStorage.setItem("mdid", mdid);
      localStorage.setItem("profile_pic", user.profile_pic);
      localStorage.setItem("daerah", JSON.stringify(daerah));
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("is_login", 1);
      setTimeout(() => {
        document.location = "/home";
      }, 3000);
    }
  };

  useEffect(() => {
    if (loading && call_action.login !== null) {
      handleLogin(call_action.login);
      setLoading(false);
    }
  }, [call_action.login]);

  useEffect(() => {
    resetStyle();
    attachLoginStyle();
  }, []);
  return (
    <>
      <section id="content">
        <div class="content-wrap">
          <div class="login-wrapper">
            <div class="login-img-wrap">
              <img src="images/login-image-trans.jpg" />
            </div>
            <div class="vertical-middle">
              <div class="login-form-wrapper">
                <h2 class="login-title">Log in</h2>
                <p>Gunakan akun indeks inovasi untuk log in ke dashboard</p>
                <form>
                  <div class="form-group">
                    <label>Email</label>
                    <div class="field-group">
                      <img src="images/ic-mail.svg" class="input-icon" />
                      <input
                        type="text"
                        class="form-control"
                        placeholder="Enter your username / e-mail address"
                        name=""
                        onBlur={(evt) => {
                          setUsername(evt.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div class="form-group">
                    <label>Password</label>
                    <div class="field-group">
                      <img src="images/ic-padlock.svg" class="input-icon" />
                      <div class="password-group">
                        <input
                          id="password-field"
                          type="password"
                          class="form-control"
                          name="password"
                          placeholder="Enter your Password"
                          onBlur={(evt) => {
                            setPassword(evt.target.value);
                          }}
                        />
                        <span
                          toggle="#password-field"
                          class="icon-eye-close field-icon toggle-password"></span>
                      </div>
                    </div>
                  </div>
                  <div class="submit-btn">
                    <a
                      href="#"
                      class="button"
                      onClick={() => {
                        login();
                      }}>
                      Login
                    </a>
                  </div>
                  {loading && (
                    <Loader
                      type="Puff"
                      color="#00BFFF"
                      height={100}
                      width={100}
                      timeout={3000} //3 secs
                    />
                  )}
                  {message && (
                    <div
                      class="alert alert-primary"
                      role="alert"
                      style={{ marginTop: 30 }}>
                      {message}
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
