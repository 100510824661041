/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect, useCallback } from "react";

import { Header, Sidebar, ContentItem, Footer, ProfilVideo } from "../../parts";
import {
  resetStyle,
  attachLandingStyle,
  attachSignedInStyle,
} from "../../libs/helper";
import OwlCarousel from "react-owl-carousel";

const Beranda = (props) => {
  const { callAction, call_action } = props;
  const [recent, setRecent] = useState([]);
  const [profil, setProfil] = useState(null);
  const [recommended, setRecommended] = useState([]);
  const [myvideos, setMyVideos] = useState([]);
  const [mypopular, setMyPopular] = useState([]);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [daerah_id, setDaerah] = useState(0);
  const [category_id, setCategory_id] = useState(1);
  const [categories, setCategories] = useState([]);

  const handleContent = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setProfil(payload.profil ? payload.profil : null);
    setRecent(payload.recent ? payload.recent : []);
    setRecommended(payload.recommended ? payload.recommended : []);
    setMyVideos(payload.myvideo ? payload.myvideo : []);
    setMyPopular(payload.mypopular ? payload.mypopular : []);
    setUpdate(update + 1);
    console.log("done");
  };

  const changeCategoryId = useCallback(
    (id) => {
      console.log(id);
      setCategory_id(id);
    },
    [category_id],
  );

  useEffect(() => {
    resetStyle();
    attachSignedInStyle();
    let mdid = parseInt(localStorage.getItem("mdid"));

    callAction(call_action, "home", {
      endpoint: "/tuxe/myhome",
    });
    setLoading(true);
    setDaerah(mdid);
    let c = localStorage.getItem('categories');
    if(c){
      setCategories(JSON.parse(c));
    }
  }, []);

  useEffect(() => {
    if (
      loading &&
      typeof call_action.home !== "undefined" &&
      call_action.home !== null
    ) {
      console.log("loading done", call_action.home);

      handleContent(call_action.home);
      setLoading(false);
    }
  }, [call_action.home]);

  return (
    <>
      <Header place="content" />
      <section
        id="slider"
        class="slider-element home-top-banner slider-parallax dark linear-bg-overlay"
        style={{
          background: "url('images/home-top-banner-bg-2.jpg')",
          backgroundSize: "cover",
        }}
        data-height-xl="670"
        data-height-lg="500"
        data-height-md="400"
        data-height-sm="420"
        data-height-xs="420">
        <div class="slider-parallax-inner">
          <div class="container clearfix">
            <div class="home-top-content">
              <h3>Selamat Datang di Tuxedovation.</h3>
              <h1>Silahkan kunjungi galeri video inovasi yang anda minati</h1>
              <p>Silahkan explorer untuk melihat semua video inovasi</p>
              <a href="/home" class="button">
                Explorer Video Inovasi
              </a>
            </div>
          </div>
        </div>
      </section>
      <section id="content">
        <div class="content-wrap">
          <div class="section nomargin dark">
            <div class="container">
              <div class="articles-wrapper">
                <div class="heading-blocks heading-block-inline">
                  <h3>Video Inovasi Anda</h3>
                  <a href="#" class="simple-link">
                    Lihat semua video
                  </a>
                </div>
                {myvideos.length > 0 && (
                  <OwlCarousel
                    dots={false}
                    id="oc-images"
                    className={
                      "owl-theme owl-carousel image-carousel carousel-widget"
                    }
                    loop
                    margin={10}
                    nav>
                    {myvideos.map((item, idx) => {
                      return (
                        <div key={idx} class="oc-item video-article-item item">
                          <a href={`/detail_inovasi/${item.id}`}>
                            <img
                              src={
                                item.has_video === 1
                                  ? item.indikator_video.thumbnail_url
                                  : "https://api.tuxedovation.com/file/novideo.jpg"
                              }
                              class="thumbnail-img"
                            />
                            <h4 class="meta-title">{item.nama}</h4>
                          </a>
                          <a href="#" class="meta-location">
                            {item.pemda}
                          </a>
                          <div class="meta-views">
                            <span class="iconify" data-icon="bi:eye"></span>{" "}
                            {item.created_at}
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                )}
              </div>
              <div class="gap-separator"></div>
              <div class="articles-wrapper">
                <div class="heading-blocks heading-block-inline">
                  <h3>Video Terpopuler Anda</h3>
                  <a href="/new_release" class="simple-link">
                    Lihat semua video
                  </a>
                </div>
                {mypopular.length > 0 && (
                  <OwlCarousel
                    dots={false}
                    id="oc-images"
                    className={
                      "owl-theme owl-carousel image-carousel carousel-widget"
                    }
                    loop
                    margin={10}
                    nav>
                    {mypopular.map((item, idx) => {
                      return (
                        <div key={idx} class="oc-item video-article-item item">
                          <a href={`/detail_inovasi/${item.id}`}>
                            <img
                              src={
                                item.has_video === 1
                                  ? item.indikator_video.thumbnail_url
                                  : "https://api.tuxedovation.com/file/novideo.jpg"
                              }
                              class="thumbnail-img"
                            />
                            <h4 class="meta-title">{item.nama}</h4>
                          </a>
                          <a href="#" class="meta-location">
                            {item.pemda}
                          </a>
                          <div class="meta-views">
                            <span class="iconify" data-icon="bi:eye"></span>{" "}
                            {item.created_at}
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                )}
              </div>
              <hr class="line-divider" />
              <div class="articles-wrapper">
                <div class="heading-blocks">
                  <h3>Pilih Kategori video</h3>
                </div>
                <div class="tabs tabs-category clearfix" id="tab-1">
                  <ul class="tab-nav clearfix">
                    <li>
                      <a href="#tabs-0" onClick={() => changeCategoryId(null)}>
                        Semua
                      </a>
                    </li>
                    {categories.map((item,idx)=>{
                      return <li>
                          <a href={`#tabs-${item.id}`} onClick={() => changeCategoryId(item.id)}>
                            {item.nama}
                          </a>
                        </li>
                    })}
                    
                   
                  </ul>
                  <div class="tab-container">
                    <div
                      class="tab-content clearfix"
                      id={`tabs-0`}>
                      <div class="fright">
                        <a href="/category" class="simple-link">
                          Lihat semua video
                        </a>
                      </div>
                      <div class="clearfix"></div>
                      <div class="row">
                        {recommended.length > 0 && (
                          <OwlCarousel
                            dots={false}
                            id="oc-images"
                            className={
                              "owl-theme owl-carousel image-carousel carousel-widget"
                            }
                            loop
                            margin={10}
                            nav>
                            {recommended.map((item, idx) => {
                              return (
                                <div
                                  key={idx}
                                  class="oc-item video-article-item item">
                                  <a href={`/detail_inovasi/${item.id}`}>
                                    <img
                                      src={
                                        item.has_video === 1
                                          ? item.indikator_video.thumbnail_url
                                          : "https://api.tuxedovation.com/file/novideo.jpg"
                                      }
                                      class="thumbnail-img"
                                    />
                                    <h4 class="meta-title">{item.nama}</h4>
                                  </a>
                                  <a href="#" class="meta-location">
                                    {item.pemda}
                                  </a>
                                  <div class="meta-views">
                                    <span
                                      class="iconify"
                                      data-icon="bi:eye"></span>{" "}
                                    {/* {item.views}&nbsp;views -  */}
                                    {item.created_at}
                                  </div>
                                </div>
                              );
                            })}
                          </OwlCarousel>
                        )}
                      </div>
                    </div>
                    {/*<div class="tab-content clearfix" id="tabs-2">
										Morbi tincidunt, dui sit amet facilisis feugiat, odio metus gravida ante, ut pharetra massa metus id nunc. Duis scelerisque molestie turpis. Sed fringilla, massa eget luctus malesuada, metus eros molestie lectus, ut tempus eros massa ut dolor. Aenean aliquet fringilla sem. Suspendisse sed ligula in ligula suscipit aliquam. Praesent in eros vestibulum mi adipiscing adipiscing. Morbi facilisis. Curabitur ornare consequat nunc. Aenean vel metus. Ut posuere viverra nulla. Aliquam erat volutpat. Pellentesque convallis. Maecenas feugiat, tellus pellentesque pretium posuere, felis lorem euismod felis, eu ornare leo nisi vel felis. Mauris consectetur tortor et purus.
									</div>
									<div class="tab-content clearfix" id="tabs-3">
										<p>Mauris eleifend est et turpis. Duis id erat. Suspendisse potenti. Aliquam vulputate, pede vel vehicula accumsan, mi neque rutrum erat, eu congue orci lorem eget lorem. Vestibulum non ante. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Fusce sodales. Quisque eu urna vel enim commodo pellentesque. Praesent eu risus hendrerit ligula tempus pretium. Curabitur lorem enim, pretium nec, feugiat nec, luctus a, lacus.</p>
										Duis cursus. Maecenas ligula eros, blandit nec, pharetra at, semper at, magna. Nullam ac lacus. Nulla facilisi. Praesent viverra justo vitae neque. Praesent blandit adipiscing velit. Suspendisse potenti. Donec mattis, pede vel pharetra blandit, magna ligula faucibus eros, id euismod lacus dolor eget odio. Nam scelerisque. Donec non libero sed nulla mattis commodo. Ut sagittis. Donec nisi lectus, feugiat porttitor, tempor ac, tempor vitae, pede. Aenean vehicula velit eu tellus interdum rutrum. Maecenas commodo. Pellentesque nec elit. Fusce in lacus. Vivamus a libero vitae lectus hendrerit hendrerit.
									</div>
									<div class="tab-content clearfix" id="tabs-4">
										Praesent in eros vestibulum mi adipiscing adipiscing. Morbi facilisis. Curabitur ornare consequat nunc. Aenean vel metus. Ut posuere viverra nulla. Aliquam erat volutpat. Pellentesque convallis. Maecenas feugiat, tellus pellentesque pretium posuere, felis lorem euismod felis, eu ornare leo nisi vel felis. Mauris consectetur tortor et purus.
									</div>*/}
                  </div>
                </div>
              </div>
              <hr class="line-divider" />
              <div class="articles-wrapper">
                <div class="heading-blocks heading-block-inline">
                  <h3>Podcast</h3>
                  {/* <a href="/podcast" class="simple-link">
                    Lihat semua video
                  </a> */}
                </div>
                <p>Belum ada video</p>
                {/* {recommended.length > 0 && (
                  <OwlCarousel
                    dots={false}
                    id="oc-images"
                    className={
                      "owl-theme owl-carousel image-carousel carousel-widget"
                    }
                    loop
                    margin={10}
                    nav>
                    {recommended.map((item, idx) => {
                      return (
                        <div key={idx} class="oc-item video-article-item item">
                          <a href={`/detail_inovasi/${item.id}`}>
                            <img
                              src={
                                item.has_video === 1
                                  ? item.indikator_video.thumbnail_url
                                  : "https://api.tuxedovation.com/file/novideo.jpg"
                              }
                              class="thumbnail-img"
                            />
                            <h4 class="meta-title">{item.nama}</h4>
                          </a>
                          <a href="#" class="meta-location">
                            {item.pemda}
                          </a>
                          <div class="meta-views">
                            <span class="iconify" data-icon="bi:eye"></span> 0
                            views - {item.created_at}
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                )} */}
              </div>
              <div class="gap-separator"></div>
              <div class="articles-wrapper">
                <div class="heading-blocks heading-block-inline">
                  <h3>Infografis</h3>
                  {/* <a href="/infografis" class="simple-link">
                    Lihat semua video
                  </a> */}
                </div>
                <p>Belum ada video</p>
                {/* {recommended.length > 0 && (
                  <OwlCarousel
                    dots={false}
                    id="oc-images"
                    className={
                      "owl-theme owl-carousel image-carousel carousel-widget"
                    }
                    loop
                    margin={10}
                    nav>
                    {recommended.map((item, idx) => {
                      return (
                        <div key={idx} class="oc-item video-article-item item">
                          <a href={`/detail_inovasi/${item.id}`}>
                            <img
                              src={
                                item.has_video === 1
                                  ? item.indikator_video.thumbnail_url
                                  : "https://api.tuxedovation.com/file/novideo.jpg"
                              }
                              class="thumbnail-img"
                            />
                            <h4 class="meta-title">{item.nama}</h4>
                          </a>
                          <a href="#" class="meta-location">
                            {item.pemda}
                          </a>
                          <div class="meta-views">
                            <span class="iconify" data-icon="bi:eye"></span> 0
                            views - {item.created_at}
                          </div>
                        </div>
                      );
                    })}
                  </OwlCarousel>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Beranda;
