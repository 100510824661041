import React, {
	useState,
	useEffect
} from "react";

import {
	Header,
	Sidebar,
	ContentItem,
	Footer
} from "../../parts";
import {resetStyle, attachLandingStyle, attachSignedInStyle} from "../../libs/helper";

import {FaPinterest,FaGoogle,FaLine,FaFacebookF,FaInstagram,FaLinkedin,FaTiktok,FaYoutube,FaTwitter,FaMobileAlt,FaInbox} from "react-icons/fa";


const AboutUs = (props) => {
	const {
		callAction,
		call_action,
		match
	} = props;

	const [data, setData] = useState(null);
	
	const [update, setUpdate] = useState(0);
	const [loading, setLoading] = useState(false);

	const handleContent = (payload) => {
		if (typeof payload === 'undefined') return;
		if (payload === null) return;
		
		setData(payload.data );
		
		setUpdate(update + 1);

	}
	useEffect(() => {
		resetStyle();
		attachSignedInStyle();
		callAction(call_action, 'about', {
			endpoint: `/tuxe/about`,
		})
		setLoading(true);

	}, []);


	useEffect(() => {
		if (loading && call_action.about !== null) {
			handleContent(call_action.about);
			setLoading(false);
		}

	}, [
		call_action.about
	]);

	return <>
		<Header place="content"/>
		<section id="content">
			<div class="content-wrap">
				<div class="section section-categories nobottommargin dark">
					<div class="container">
						<div class="articles-wrapper">
							<div class="heading-blocks">
								<h3>Tentang Kami</h3>
							</div>
							{data && <div class="row">
								<div class="col-sm-12">
									<h3>{data.about ? data.about.judul : ''}</h3>
									<div dangerouslySetInnerHTML={{__html:data.about ? data.about.content : ''}}/>
									<hr/>
								</div>

							</div>}
							<div class="row">
					                            <div class="col-sm-3">ALAMAT</div>
					                            <div class="col-sm-9 pb-2">
					                                BADAN PENELITIAN DAN PEMGEMBANGAN <br/>
					                                KEMENTERIAN DALAM NEGERI <br/>
					                                GEDUNG MATRA 132 <br/>
					                                JALAN KRAMAT RAYA NO. 132, SENEN, JAKARTA PUSAT, 10430                       
					                            </div>

					        </div>
					        <div class="row">
                            <div class="col-sm-3">WEBSITE</div>
                            <div class="col-sm-9 pb-2">
                                <a href="https://litbang.kemendagri.go.id/">https://litbang.kemendagri.go.id</a>                        
                            </div>
                            <div class="col-sm-3">PRODUK LAYANAN</div>
                            <div class="col-sm-9 pb-2">
                                <ul>
                                    <li><a href="http://indeks.inovasi.otda.go.id/layanan/">Indeks Inovasi Daerah</a></li>
                                    <li>
                                        <a href="https://play.google.com/store/apps/details?id=id.go.inovasi.otda&amp;hl=en">Puja Indah</a>
                                        <br/>
                                        info produk : <a href="http://litbang.kemendagri.go.id/website/puja-indah-pusat-jejaring-inovasi-daerah/">http://litbang.kemendagri.go.id/website/puja-indah-pusat-jejaring-inovasi-daerah/</a>
                                    </li>
                                    <li><a href="http://quicksurvey.litbang.kemendagri.go.id/">E- research</a></li>
                                    <li><a href="http://inovasi.otda.go.id/layanan/login">Layanan konsultasi</a></li>
                                    <li><a href="http://jurnal.kemendagri.go.id/index.php/jbp">E-jurnal</a></li>

                                </ul>
                            </div>

                            <div class="col-sm-3">JAM KUNJUNGAN</div>
                            <div class="col-sm-9 pb-2">
                                SENIN – JUMAT (JAM KERJA) 
                            </div>

                            <div class="col-sm-3">MEDIA SOSIAL</div>
                            <div class="col-sm-9 pb-2">
                                <ul class="list-unstyled">
                                    <li><FaInstagram/> puslitnovkemendagri</li>
                                    <li><FaFacebookF/> puslitnov Kemendagri</li>
                                    <li><FaPinterest/> puslitnovkemendagri</li>
                                    <li><FaLine/> puslitnovkemendagri</li>
                                    <li><FaGoogle/> puslitbangnovda@gmail.com</li>
                                    <li><FaYoutube/> <a href="https://www.youtube.com/channel/UCmgDAjjLP2pxV6xi1v6hbwg?view_as=subscriber">Youtube Channel</a></li>
                                </ul>
                            </div>
                            
                        </div>
                 <div class="row">
                            <div class="col-sm-3">Telephone</div>
                            <div class="col-sm-9 pb-2">
                                021-31904409/0813-9305-5436 <br/>
                                Whatsapp: 085213108032
                            </div>

                            <div class="col-sm-3">Email</div>
                            <div class="col-sm-9 pb-2">
                                <ul>
                                    <li>Puslitbangnovda@gmail.com</li>
                                    <li>Tuinovda@gmail.com</li>
                                </ul>                  
                            </div>
                            <div class="col-sm-3">Fax</div>
                            <div class="col-sm-9 pb-2">
                                (021) 3157116
                            </div>

                            <div class="col-sm-3">Jam Layanan Online</div>
                            <div class="col-sm-9">
                                Senin s/d Jumat 08.00 - 16.00 (Kecuali libur)
                            </div>
                            
                        </div>

					</div>
						</div>
						
					</div>
				
			</div>
		</section>
		
		<Footer/>
		</>
	
};

export default AboutUs;