import React from "react";
import { IoShareSocialSharp, IoCloudDownloadSharp, IoDownloadOutline, IoEyeSharp, IoLocationSharp, IoCalendarNumberSharp }
	from "react-icons/io5";
export default function (props) {
	const { data } = props;

	return <>
		{data.length > 0 &&
			<div class="row">
				{data.map((item, idx) => {
					return <div key={idx} class="col-md-3 col-6">
						<div class="video-article-item">
							<a href={`/detail_inovasi/${item.id}`}>
								<img src={item.has_video === 1 ? item.indikator_video.thumbnail_url : 'https://api.tuxedovation.com/file/novideo.jpg'} class="thumbnail-img" />
								<h4 class="meta-title">{item.nama}</h4>
							</a>
							<a href="#" class="meta-location">{item.pemda}</a>
							<div class="meta-views"> {item.created_at}</div>
							{/* <IoEyeSharp /> {item.total_views || 0} views - */}
						</div>
					</div>

				})}

				<div class="col-md-12 center">
					<a href="#" class="button load-more-button">Load more</a>
				</div>
			</div>
		}
	</>

}