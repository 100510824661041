import React, { useState, useEffect } from "react";
import { IoIosHelpBuoy, IoIosMegaphone } from "react-icons/io";
import {
  HiOutlineHome,
  HiViewGrid,
  HiOutlineStar,
  HiOutlineNewspaper,
  HiOutlineLocationMarker,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { AiFillAudio } from "react-icons/ai";
import { MdHelpOutline, MdShare } from "react-icons/md";

const Header = (props) => {
  const { isLogin, place } = props;
  const [txt, setText] = useState(null);
  const [user, setUser] = useState(null);
  const [is_login, setIsLogin] = useState(false);
  const [search, setSearch] = useState(null);
  let profile_pic = localStorage.getItem("profile_pic");
  useEffect(() => {
    let user = localStorage.getItem("user");
    let login_state = localStorage.getItem("is_login");

    if (user) {
      setUser(JSON.parse(user));
    }
    setIsLogin(login_state === "1" ? true : false);
    console.log({ login_state, user });
  }, []);

  const checkPath = (path) => {
    let url = window.location.pathname;
    if (url.includes(path)) {
      return true;
    } else {
      return false;
    }
  };

  if (place === "content") {
    return (
      <>
        <div id="top-bar">
          <div class="container">
            <div class="top-bar-wrap">
              <div id="header-trigger">
                <i class="icon-line-menu"></i>
                <i class="icon-line-menu"></i>
              </div>
              <div id="top-search">
                <a href="#" id="top-search-trigger">
                  <i class="icon-search3"></i>
                  <i class="icon-line-cross"></i>
                </a>
                <form action="/search">
                  <input
                    type="text"
                    name="q"
                    class="form-control"
                    
                    placeholder="Cari video"
                    onBlur={(evt) => {
                      setSearch(evt.target.value);
                    }}
                  />
                  <a href="#" class="search-btn">
                    <span class="iconify" data-icon="akar-icons:search"></span>
                  </a>
                </form>
              </div>
              {is_login && (
                <div id="top-account" class="dropdown">
                  <a
                    href="#"
                    class="dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="true">
                    <img src={profile_pic} class="profile-pic" />
                    <span class="profile-name">{user?.name}</span>
                  </a>
                  <ul
                    class="dropdown-menu dropdown-menu-right"
                    aria-labelledby="dropdownMenu1">
                    <a class="dropdown-item tleft" href="/profile">
                      Profile
                    </a>
                    {/*<a class="dropdown-item tleft" href="#">Messages <span class="badge badge-pill badge-secondary fright" style={{marginTop:3}}>5</span></a>
							<a class="dropdown-item tleft" href="#">Settings</a>*/}
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item tleft" href="/logout">
                      Logout <i class="icon-signout"></i>
                    </a>
                  </ul>
                </div>
              )}
              {!is_login && (
                <div class="sign-in-button">
                  <a href="/login" class="button">
                    Masuk
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        <header id="header" class="no-sticky dark">
          <div id="header-wrap">
            <div id="primary-menu-trigger">
              <i class="icon-line-menu"></i>
            </div>
            <div id="logo" class="nobottomborder">
              <a href="/" class="standard-logo">
                <img src="/images/vertical-logo.svg" />
              </a>
              <a href="/" class="retina-logo">
                <img src="/images/vertical-logo.svg" />
              </a>
            </div>
            <nav id="primary-menu" class="nobottomborder">
              <ul>
                <li className={checkPath("home") ? "current" : ""}>
                  <a href="/home">
                    <HiOutlineHome />
                    <div>Beranda</div>
                  </a>
                </li>
                <li className={checkPath("category") ? "current" : ""}>
                  <a href="/category">
                    <HiViewGrid />
                    <div>Kategori</div>
                  </a>
                </li>
                <li className={checkPath("popular") ? "current" : ""}>
                  <a href="/popular">
                    <HiOutlineStar />
                    <div>Populer</div>
                  </a>
                </li>
                <li className={checkPath("new_release") ? "current" : ""}>
                  <a href="/new_release">
                    <HiOutlineNewspaper />
                    <div>Terbaru</div>
                  </a>
                </li>
                <li className={checkPath("podcast") ? "current" : ""}>
                  <a href="/podcast">
                    <AiFillAudio />
                    <div>Podcast</div>
                  </a>
                </li>
                <li className={checkPath("infografis") ? "current" : ""}>
                  <a href="/infografis">
                    <IoIosMegaphone />
                    <div>Infografis</div>
                  </a>
                </li>
                <li className={checkPath("map") ? "current" : ""}>
                  <a href="/map">
                    <HiOutlineLocationMarker />
                    <div>Peta</div>
                  </a>
                </li>
                <li className={checkPath("about-us") ? "current" : ""}>
                  <a href="/about-us">
                    <HiOutlineUserGroup />
                    <div>Tentang Kami</div>
                  </a>
                </li>
                <li className={checkPath("faq") ? "current" : ""}>
                  <a href="/faq">
                    <MdHelpOutline />
                    <div>FAQ</div>
                  </a>
                </li>

                <li>
                  <a href="https://indeksinovasidaerah.tawk.help/">
                    <IoIosHelpBuoy />
                    <div>Helpdesk IID</div>
                  </a>
                </li>
                <li className={checkPath("stats") ? "current" : ""}>
                  <a href="/stats">
                    <MdShare />
                    <div>STATS</div>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
      </>
    );
  } else {
    return (
      <header id="header" class="transparent-header dark">
        <div id="header-wrap">
          <div class="container">
            <div
              id="logo"
              style={{
                display: "flex",
              }}>
              <a href="/" class="standard-logo">
                <img style={{ marginRight: 8 }} src="/logoiga.png" />
              </a>
              <a href="/" class="standard-logo">
                <img src="/images/main-logo.svg" />
              </a>
              <a href="/" class="retina-logo">
                <img src="/images/main-logo.svg" />
              </a>
            </div>
            <div class="sign-in-button">
              <a href="/login" class="button">
                Masuk
              </a>
            </div>
          </div>
        </div>
      </header>
    );
  }
};

export default Header;
