import React, { useCallback, useEffect, useState } from "react";
import { attachSignedInStyle } from "../../libs/helper";
import { Header } from "../../parts";
import { Line } from "@iftek/react-chartjs-3";

function Stats(props) {
  const { callAction, call_action, match } = props;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [getChart, setGetChart] = useState(0);

  const handleContent = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setData([payload] || []);
  };

  const load = () => {
    callAction(call_action, "stat_data", {
      endpoint: `/tuxe/stats`,
    });
  };

  useEffect(() => {
    load();
    attachSignedInStyle();
    setLoading(true);
    //     return () => {};
  }, []);

  useEffect(() => {
    if (loading && call_action.stat_data !== null) {
      handleContent(call_action.stat_data);
      setLoading(false);
    }
  }, [call_action.stat_data]);

  const dataChartViews = data.length > 0 && {
    labels: data[0].daily_views.map((datas) => datas.tgl),
    datasets: [
      {
        label: "Total Views",
        data: data[0].daily_views.map((datas) => datas.total),
        borderColor: "#fff",
        borderWidth: 2,
      },
    ],
  };
  const dataChartLikes = data.length > 0 && {
    labels: data[0].daily_likes.map((datas) => datas.tgl),
    datasets: [
      {
        label: "Total Likes",
        data: data[0].daily_likes.map((datas) => datas.total),
        borderColor: "#fff",
        borderWidth: 2,
      },
    ],
  };

  const setDataChart = useCallback((value) => {
    setGetChart(value);
  }, []);

  return (
    <>
      <Header place="content" />
      <section className="content">
        <div className="content-wrap">
          <div className="section section-categories nobottommargin dark">
            <div className="container">
              <div className="articles-wrapper">
                <div class="heading-blocks">
                  <h3>Stats Video</h3>
                </div>
                {data.length > 0 && (
                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className="card card-button"
                        onClick={() => setDataChart(0)}
                        style={{
                          borderColor: getChart === 0 ? "#fff" : "#343a40",
                        }}>
                        <div className="card-body">
                          <p className="card-title text-bold text-center">
                            Views
                          </p>
                          <p
                            className="text-center text-bold"
                            style={{
                              fontSize: 24,
                              fontWeight: 200,
                            }}>
                            {data[0].summary.total_views}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="card card-button"
                        onClick={() => setDataChart(1)}
                        style={{
                          borderColor: getChart === 1 ? "#fff" : "#343a40",
                        }}>
                        <div className="card-body">
                          <p className="card-title text-bold text-center">
                            Likes
                          </p>
                          <p
                            className="text-center text-bold"
                            style={{
                              fontSize: 24,
                              fontWeight: 200,
                            }}>
                            {data[0].summary.total_likes}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card border-dark">
                        <div className="card-body">
                          <p className="card-title text-bold text-center">
                            Videos
                          </p>
                          <p
                            className="text-center text-bold"
                            style={{
                              fontSize: 24,
                              fontWeight: 200,
                            }}>
                            {data[0].summary.total_videos}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      {data.length > 0 && (
                        <Line
                          data={
                            getChart === 0 ? dataChartViews : dataChartLikes
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Stats;
