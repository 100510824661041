import React from "react";
import { Link } from "react-router-dom";
import { Images } from "../Assets";

const Footer = () => {
  return (
    <footer id="footer" class="dark" style={{ backgroundColor: "#5F0000" }}>
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-4 order-2 order-md-1">
            <div class="footer-menu">
              <a href={"/about-us"}>About Us</a>
              <a href={"https://indeksinovasidaerah.tawk.help/"}>Help</a>
              <a href={"/faq"}>FAQ</a>
            </div>
          </div>
          <div class="col-md-4 order-1 order-md-2 center">
            <img src="/images/main-logo.svg" class="footers-logo" />
          </div>
          <div class="col-md-4 order-3 order-md-3">
            <div class="footer-social">
              <label>FOLLOW ON</label>
              <div class="social-items">
                <a
                  href="https://www.facebook.com/Kemendagri/"
                  target="_blank"
                  class="social-icon si-large si-borderless nobottommargin si-facebook"
                  rel="noreferrer">
                  <i class="icon-facebook"></i>
                  <i class="icon-facebook"></i>
                </a>
                <a
                  href="https://twitter.com/Kemendagri_RI"
                  target="_blank"
                  class="social-icon si-large si-borderless nobottommargin si-instagram"
                  rel="noreferrer">
                  <i class="icon-twitter"></i>
                  <i class="icon-twitter"></i>
                </a>
              </div>
            </div>
          </div>
          <div class="col-md-12 order-4 order-md-4">
            <div class="footer-copyright">&copy; 2022 All rights reserved</div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
