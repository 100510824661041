import React, { useState, useEffect } from "react";
import withSession from "./withSession";
import withAuth from "./withAuth";

import Home from "./pages/Home";
import Category from "./pages/Category";
import Detail from "./pages/Detail";
import Popular from "./pages/Popular";
import Podcast from "./pages/Podcast";

import NewRelease from "./pages/NewRelease";
import AboutUs from "./pages/AboutUs";
import Map from "./pages/Map";
import Search from "./pages/Search";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Landing from "./pages/Landing";
import Beranda from "./pages/Beranda";
import Stats from "./pages/Stats";
import Profile from "./pages/Profile";
import Infografis from "./pages/Infografis";

import FAQ from "./pages/FAQ";

const domain = process.env.REACT_APP_DOMAIN;
let host = window.location.host;
let subdomains = host.split(".");

let routes = [];

routes = [
  {
    path: "/",
    exact: true,
    component: Landing,
  },
  {
    path: "/home",
    exact: true,
    component: Beranda,
  },
  {
    path: "/login",
    exact: true,
    component: Login,
  },
  {
    path: "/infografis",
    exact: true,
    component: withSession(Infografis),
  },
  {
    path: "/Stats",
    exact: true,
    component: withSession(Stats),
  },

  {
    path: "/Stats",
    exact: true,
    component: withSession(Stats),
  },
  {
    path: "/Profile",
    exact: true,
    component: withSession(Profile),
  },

  {
    path: "/logout",
    exact: true,
    component: withSession(Logout),
  },
  {
    path: "/category",
    exact: true,
    component: withSession(Category),
  },
  {
    path: "/category/:id",
    exact: true,
    component: withSession(Category),
  },
  {
    path: "/detail_inovasi/:id",
    exact: true,
    component: withSession(Detail),
  },
  {
    path: "/popular",
    exact: true,
    component: withSession(Popular),
  },
  {
    path: "/podcast",
    exact: true,
    component: withSession(Podcast),
  },
  {
    path: "/new_release",
    exact: true,
    component: withSession(NewRelease),
  },
  {
    path: "/search",
    exact: true,
    component: withSession(Search),
  },
  {
    path: "/about-us",
    exact: true,
    component: withSession(AboutUs),
  },
  {
    path: "/faq",
    exact: true,
    component: withSession(FAQ),
  },
  {
    path: "/map",
    exact: true,
    component: withSession(Map),
  },
];

export default routes;
