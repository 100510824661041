import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Footer } from "./parts";
import routes from "./routes";
const loadScript = function (src) {
  var tag = document.createElement("script");
  tag.async = false;
  tag.src = src;
  document.getElementsByTagName("body")[0].appendChild(tag);
};
function App(props) {
  let currentPath = window.location.pathname;
  let isAppBarShow = true;
  if (currentPath === "/login") isAppBarShow = false;
  useEffect(() => {
    loadScript('/assets/js/jquery.js');
    loadScript('/assets/js/plugins.js');
    loadScript('/assets/js/functions.js');
   
  }, []);
  return (
    <Router>
      <Switch>
        {routes.map((route, index) => (
          // Render more <Route>s with the same paths as
          // above, but different components this time.
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
          />
        ))}
      </Switch>
    </Router>
  );
}

export default App;
