import { combineReducers } from "redux";
import CreateStore from "./CreateStore";
import CrudStore from "./CrudStore";
import crud from "./crud";
import crud_add from "./crud_add";
import crud_delete from "./crud_delete";
import crud_get from "./crud_get";
import crud_update from "./crud_update";
import do_action from "./do_action";
import call_action from "./call_action";

//auto-create CRUD actions / reducers
const profile = CrudStore("profile","/profile");
const courses = CrudStore("courses","/courses");


const preferred = CrudStore("preferred","/preferred");
const report = CrudStore("report","/report");
const invoice = CrudStore("invoice","/invoice");

//auto create custom actions / reducers
const [login, loginAction] = CreateStore("LOGIN","/login","post");
const [register, registerAction] = CreateStore("REGISTER","/register","post");
const [refresh_account, refreshAccountAction] = CreateStore("REFRESH_ACCOUNT", "/me", "get", true);

/*const [content, getContentAction] = CreateStore("CONTENT","/content","get");
const [buy, buyAction] = CreateStore("BUY","/buy","post",true);
const [sell, sellAction] = CreateStore("SELL","/sell","post", true);
const [upcoming_courses, upcomingCoursesAction] = CreateStore("UPCOMING_COURSE","/course/available","pagination",true);
const [course_request, courseRequestAction] = CreateStore("COURSE_REQUEST","/course/request","pagination",true);
const [taken_courses, takenCoursesAction] = CreateStore("TAKEN_COURSE","/course/ongoing","pagination",true);
const [history, historyAction] = CreateStore("COURSE_HISTORY","/course/history","pagination",true);
*/
const [summary, summaryAction] = CreateStore("SUMMARY","/metrics/summary","get",true);
//const laporan = CrudStore("LAPORAN","/laporan","post");

const [upload, uploadAction] = CreateStore("UPLOAD","/media/upload","upload", true);


//setup list of actions
const Actions = {
  loginAction,
  registerAction,
  summaryAction,
  uploadAction,
  refreshAccountAction
 // ...laporan.actions,
};

export { Actions };

//combine the reducers
export default combineReducers({
  crud,
  crud_add,
  crud_delete,
  crud_update,
  crud_get,
  do_action,
  call_action,
  login,
  register,
  summary,
  upload,
  refresh_account
 // ...laporan.reducers
});
