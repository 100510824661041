
let INITIAL_STATE = {
    data:null,
    payload:null,
    fetching:false,
    error:false,
    message:"",
}
export default (state = INITIAL_STATE, action) => {
  
  switch (action.type) {
    case "CALL_ACTION":
      return {
        ...action,
        data: action.data,
        fetching:true,
    };
    case "CALL_ACTION_SUCCESS":
      return {
        ...action,
        fetching:false
    };
    case "CALL_ACTION_ERROR":
      return {
        ...action,
        error: true,
        fetching:false,
        message: action.message
    };
    case "CALL_ACTION_RESET":
      return {
        ...action,
        error: false,
        fetching:false,
        message: null,
        data:null,
        payload:null,
    };
    default:
      return state;
  }
};