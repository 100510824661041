import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
//import App from "./pages/App";
import App from "./App";
import configureStore from "./store";
import { PersistGate } from "redux-persist/integration/react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

let { store, persistor } = configureStore();

if(localStorage.getItem("token") === null){
  localStorage.setItem("token",process.env.REACT_APP_TOKEN);
}


ReactDOM.render(
 
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  ,
  document.getElementById("wrapper")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
