import React, { useState, useEffect, useCallback } from "react";

import { Header, Sidebar, ContentItem, Footer } from "../../parts";
import Loader from "react-loader-spinner";
import {
  attachContentStyle,
  attachSignedInStyle,
  resetStyle,
} from "../../libs/helper";
import autoMergeLevel1 from "redux-persist/es/stateReconciler/autoMergeLevel1";
import { IoEarSharp } from "react-icons/io5";

const Category = (props) => {
  const { callAction, call_action, match } = props;
  const [data, setData] = useState([]);
  const [category, setCategory] = useState(null);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [klaster, setKlaster] = useState([]);
  const [covid, setCovid] = useState([]);
  const [urusan, setUrusan] = useState([]);
  const [refresh, setRefresh] = useState(0);
  const [klaster_id, setKlasterId] = useState(0);
  const [covid_id, setCovidId] = useState(0);
  const [urusan_id, setUrusan_id] = useState(0);
  const [offset, setOffset] = useState(0);
  const [search, setSearch] = useState(null);
  const [nextOffset, setNextOffset] = useState(0);

  const initCategories = ()=>{
    try{
       let cat = JSON.parse(localStorage.getItem("categories"));
      let kt = JSON.parse(localStorage.getItem("klaster"));
      if(cat) setKlaster(kt);
      if(cat) setUrusan(cat);
    }catch(err){}
   
  }
  const handleContent = (payload) => {
    console.log('handleContent',payload);
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setData([...data, ...payload.inovasi] || []);
    setCategory(payload.category || null);
    setKlaster(payload.klaster || []);
    setCovid(payload.covid || []);
    setUrusan(payload.categories || []);
    setNextOffset(payload.next_offset || 0);
    loadCategories();
    setLoading(false);
  };
  const load = () => {
    callAction(call_action, "category_index", {
      endpoint: `/tuxe/inovasi/${urusan_id}`,
      data: {
        offset: offset,
        klaster: klaster_id,
        covid: covid_id,
        search,
      },
    });
    setLoading(true);
  };
  const loadCategories = () => {
    callAction(call_action, 'categories', {
      endpoint: '/tuxe/categories'
    });
  }
  const handleLoadMore = useCallback((nextOffset) => {
    setOffset(nextOffset);
  }, []);

  useEffect(() => {
    setUrusan_id(match.params.id || 0);
    resetStyle();
    attachSignedInStyle();
    setRefresh(refresh + 1);
    load();
  }, []);

  useEffect(() => {
    if (loading && call_action.category_index !== null) {
      handleContent(call_action.category_index);
      
    }
  }, [call_action.category_index]);

  useEffect(() => {
    load();
  }, [offset]);

  useEffect(() => {
    if(call_action.categories){
      let payload = call_action.categories;
      if(payload.categories) localStorage.setItem("categories",JSON.stringify(payload.categories));
      if(payload.klaster) localStorage.setItem("klaster",JSON.stringify(payload.klaster));
      initCategories();
    }
  }, [
    call_action.categories]);

  return (
    <>
      <Header place="content" />
      {/* <Sidebar {...props} /> */}
      <section className="content">
        <div className="content-wrap">
          <div className="section section-categories nobottommargin dark">
            <div className="container">
              <div className="articles-wrapper">
                <h3 className="section-title mt-4">
                  {" "}
                  {category ? category.nama.toUpperCase() : "Semua Video"}{" "}
                </h3>

                <div className="my-4 row">
                  <div className="col-md-3">
                    <select
                      name="klaster"
                      className="py-3 form-select"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        fontWeight: "bold",
                        background: "#E6E6E6",
                        borderRadius: 10,
                        maxWidth: "100%",
                        marginBottom: 10,
                        marginRight: 4,
                      }}
                      onChange={(evt) => {
                        setKlasterId(evt.target.value);
                      }}>
                      <option
                        data-bind="attr:{value: id},text:klaster"
                        value="0">
                        Semua Klaster
                      </option>
                      {klaster.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.klaster}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/*}
                  <div className="col-md-3">
                    <select
                      name="covid"
                      className="py-3 form-select"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        fontWeight: "bold",
                        background: "#E6E6E6",
                        borderRadius: 10,
                        maxWidth: "100%",
                        marginBottom: 10,
                        marginRight: 4,
                      }}
                      onChange={(evt) => {
                        setCovidId(evt.target.value);
                      }}>
                      <option
                        data-bind="attr:{value: id},text:nama_klaster"
                        value="0">
                        Semua Inovasi
                      </option>
                      {covid.map((item, index) => {
                        return (
                          <option key={index} value={item.id}>
                            {item.nama_klaster}
                          </option>
                        );
                      })}
                    </select>
                  </div>*/}
                  <div clasName="col-md-3">
                    <select
                      name="category_id"
                      className="py-3 mx-auto form-select"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        fontWeight: "bold",
                        background: "#E6E6E6",
                        borderRadius: 10,
                        maxWidth: 266,
                        marginBottom: 10,
                        marginRight: 4,
                      }}
                      onChange={(evt) => {
                        setUrusan_id(evt.target.value);
                      }}>
                      <option data-bind="attr:{value: id},text:nama" value="0">
                        Semua Urusan Pemerintah
                      </option>
                      {urusan.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item.id}
                            selected={
                              parseInt(item.id) === parseInt(urusan_id)
                                ? "selected"
                                : ""
                            }>
                            {item.nama}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="search"
                      placeholder="nama pemda atau inovasi"
                      onBlur={(evt) => {
                        setSearch(evt.target.value);
                      }}
                      className="py-3 form-control"
                      style={{
                        textAlign: "center",
                        width: "100%",
                        fontWeight: "bold",
                        background: "#E6E6E6",
                        borderRadius: 10,
                        maxWidth: "100%",
                        marginBottom: 10,
                        marginRight: 4,
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="button"
                      name="btn"
                      value="CARI"
                      style={{
                        backgroundColor: "#FED530",
                        width: "100%",
                        height: 60,
                        borderRadius: 10,
                      }}
                      onClick={() => {
                        setOffset(0);
                        setRefresh(refresh + 1);
                        setData([]);
                        load();
                      }}
                    />
                  </div>
                </div>

                {loading && (
                  <Loader
                    style={{ margin: "0 auto", textAlign: "center" }}
                    type="Puff"
                    color="#00BFFF"
                    height={60}
                    width={60}
                    timeout={3000} //3 secs
                  />
                )}
                <>
                  {data.length > 0 && (
                    <div class="row">
                      {data.map((item, idx) => {
                        return (
                          <div key={idx} class="col-md-3 col-6">
                            <div class="video-article-item">
                              <a href={`/detail_inovasi/${item.id}`}>
                                <img
                                  src={
                                    item.has_video === 1
                                      ? item.indikator_video.thumbnail_url
                                      : "https://api.tuxedovation.com/file/novideo.jpg"
                                  }
                                  class="thumbnail-img"
                                />
                                <h4 class="meta-title">{item.nama}</h4>
                              </a>
                              <a href="#" class="meta-location">
                                {item.pemda}
                              </a>
                              <div class="meta-views">
                                <IoEarSharp /> 
                                {/* {item.total_views || 0} views -{" "} */}
                                {item.created_at}
                              </div>
                            </div>
                          </div>
                        );
                      })}

                      <div class="col-md-12 center">
                        <button
                          // href="#"
                          class="button load-more-button"
                          onClick={() => handleLoadMore(nextOffset)}>
                          {loading ? "Loading..." : "Load More"}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Category;
