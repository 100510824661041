import React, {
	useState,
	useEffect
} from "react";

import {
	Header,
	Sidebar,
	ContentItem,
	Footer
} from "../../parts";

import {resetStyle, attachLandingStyle, attachSignedInStyle} from "../../libs/helper";

const FAQ = (props) => {
	const {
		callAction,
		call_action,
		match
	} = props;

	const [data, setData] = useState(null);
	
	const [update, setUpdate] = useState(0);
	const [loading, setLoading] = useState(false);

	const handleContent = (payload) => {
		if (typeof payload === 'undefined') return;
		if (payload === null) return;
		
		setData(payload.data );
		
		setUpdate(update + 1);

	}
	useEffect(() => {
		resetStyle();
		attachSignedInStyle();
		callAction(call_action, 'faq', {
			endpoint: `/tuxe/faq`,
		})
		setLoading(true);

	}, []);


	useEffect(() => {
		if (loading && call_action.faq !== null) {
			handleContent(call_action.faq);
			setLoading(false);
		}

	}, [
		call_action.faq
	]);


	return <>
		<Header place="content"/>
		<section id="content">
			<div class="content-wrap">
				<div class="section section-categories nobottommargin dark">
					<div class="container">
						<div class="articles-wrapper">
							<div class="heading-blocks">
								<h3>FAQ</h3>
							</div>
						</div>
						<div class="row">
				         	<div class="col-sm-12">
				         		{data && data.map((item,index)=>{
				         			return (<>
				         				<h4>{item.judul}</h4>
				         				<div dangerouslySetInnerHTML={{__html:item.content}}></div></>)
				         		})}
				         		
				         	</div>     
				        </div>
						
					</div>
				</div>
			</div>
		</section>
	
		
       

		<Footer/>
		</>
	
};

export default FAQ;