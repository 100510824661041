import React, { useCallback, useEffect, useState } from "react";
import Loader from "react-loader-spinner";
import ReactPlayer from 'react-player/lazy'
import {
  FacebookShareButton,
  FacebookIcon,
  InstapaperShareButton,
  LineShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import {
  IoShareSocialSharp,
  IoCloudDownloadSharp,
  IoDownloadOutline,
  IoEyeSharp,
  IoLocationSharp,
  IoThumbsUpSharp,
  IoCalendarNumberSharp,
} from "react-icons/io5";
import accounting from "accounting";
import Related from "../../parts/Related";
export default function (props) {
  const {
    data,
    loading,
    video,
    category,
    related,
    dataComment,
    postComment,
    loadMoreComment,
    loadingComment,
    postLike,
    postUnlike,
    Dokumen,
    Mutate,
  } = props;
  const LANDING_URL = process.env.REACT_APP_URL;
  const profile_pic = localStorage.getItem("profile_pic");
  const [shareUrl, setUrl] = useState("");
  const [txt, setTxt] = useState("");
  const [replyID, setReplyID] = useState(null);
  let user = localStorage.getItem("user");
  const [share, setShare] = useState(false);
  useEffect(() => {
    if (data) setUrl(`${LANDING_URL}/detail_inovasi/${data.id}`);
  }, [data]);

  const handleChangeReplyid = useCallback((id) => {
    setReplyID(id);
  }, []);

  const renderSwitch = (id) => {
    switch (id) {
      case 15:
        return "Regulasi Inovasi Daerah";
      case 16:
        return "Ketersediaan SDM Terhadap Inovasi Daerah";
      case 33:
        return "Kecepatan penciptaan inovasi";
      case 34:
        return "Kemanfaatan Inovasi";
    }
  };
  const cleanstyle = (htmlString)=>{
      if(!htmlString) return htmlString;
      const htmlNode = document.createElement('div');
      htmlNode.innerHTML = htmlString;
      htmlNode.querySelectorAll('*').forEach(function(node) {
          node.removeAttribute('style');
      });
     
      return htmlNode.innerHTML;
  }
  let videoWidth = (window.innerHeight*0.8) * 16 / 9;
  let videoHeight = window.innerHeight*0.8;
  if(window.innerHeight > window.innerWidth){
    videoWidth  = window.innerWidth;
    videoHeight = window.innerWidth* 9/16;
  }

  

  return (
    <div class="content-wrap">
      {(data && data.has_video === 1 && video) && (
        <div
          class="video-embed-wrapper"
          style={{ backgroundColor: "#000", textAlign: "center", margin:'0 auto', marginTop:70}}>
          
          {video && <ReactPlayer style={{margin:'0 auto'}} controls={true} url={video.file_url || video.youtube} width={videoWidth} height={videoHeight}/>}
        </div>
      )}
      {loading && (
        <Loader
          type="Puff"
          color="#00BFFF"
          height={100}
          width={100}
          timeout={3000} //3 secs
        />
      )}

      {data && (
        <div class="section nomargin nopadding dark">
          <div class="container">
            <div class="main-title-wrapper">
              <div class="title-wrap">
                <h1>{data.nama}</h1>
                <div class="metas-wrap">
                  <div class="meta-item">
                    <IoLocationSharp />
                    <span class="meta-inner">{data.pemda}</span>
                  </div>
                  <div class="meta-item">
                    <IoCalendarNumberSharp />
                    <span class="meta-inner">{data.created_at}</span>
                  </div>
                  <div class="meta-item">
                    <IoEyeSharp />
                    <span class="meta-inner">
                      {accounting.formatNumber(data.total_views, ".", 0)} x di
                      tonton
                    </span>
                  </div>
                </div>
              </div>
              <div class="actions-wrap">
                <a
                  href={user ? "#" : "/login"}
                  onClick={() => {
                    data.hasLike === 1 ? postUnlike() : postLike();
                    Mutate();
                  }}>
                  <div
                    class={
                      data.hasLike === 1 ? "action-item isLiked" : "action-item"
                    }>
                    <IoThumbsUpSharp />
                    <p>Like</p>
                  </div>
                </a>
                <a
                  href="#"
                  onClick={() => {
                    setShare(!share);
                  }}>
                  <div class="action-item">
                    <IoShareSocialSharp />
                    <p>Share</p>
                  </div>
                </a>
                <a
                  href={user ? data.indikator_video.file_url : "/login"}
                  target={user ? "_blank" : ""}
                  rel="noreferrer">
                  <div class="action-item">
                    <IoCloudDownloadSharp />
                    <p>Download</p>
                  </div>
                </a>
              </div>
            </div>
            <div
              className={
                share ? "main-title-wrapper" : "main-title-wrapper hidden"
              }>
              {" "}
              {/* sharebutton */}
              <div className="metas-wrap">
                <TelegramShareButton
                  url={`https://tuxedovation.bigre.id/${data.id}`}
                  style={{ marginRight: 10 }}>
                  <TelegramIcon size={40} round={true} />
                </TelegramShareButton>
                <TwitterShareButton
                  url={`https://tuxedovation.bigre.id/${data.id}`}
                  style={{ marginRight: 10 }}>
                  <TwitterIcon size={40} round={true} />
                </TwitterShareButton>
                <WhatsappShareButton
                  url={`https://tuxedovation.bigre.id/${data.id}`}
                  style={{ marginRight: 10 }}>
                  <WhatsappIcon size={40} round={true} />
                </WhatsappShareButton>
                <EmailShareButton
                  url={`https://tuxedovation.bigre.id/${data.id}`}
                  style={{ marginRight: 10 }}>
                  <EmailIcon size={40} round={true} />
                </EmailShareButton>
              </div>
            </div>
            <div class="main-contents">
              <div class="content-item">
                <h3>Latar Belakang</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: cleanstyle(data.latar_belakang),
                  }}></div>
              </div>
              <div class="content-item">
                <h3>Tujuan</h3>
                <div dangerouslySetInnerHTML={{ __html: cleanstyle(data.tujuan) }}></div>
              </div>
              <div class="content-item">
                <h3>Manfaat</h3>
                <div dangerouslySetInnerHTML={{ __html: cleanstyle(data.manfaat) }}></div>
              </div>
            </div>
            <hr />
            {user && <div class="downloadable-content">
              <h3>Unduh Dokumen Pendukung</h3>

              {user &&
                Dokumen.length > 0 &&
                Dokumen.map((item) => {
                  return (
                    <>
                      <prev style={{ fontSize: 20 }}>
                        {renderSwitch(item.indikator_id)}
                      </prev>
                      <a
                        href={item.file_url}
                        target="_blank"
                        key={item.id}
                        rel="noreferrer">
                        <div class="download-item" style={{ maxWidth: "90%" }}>
                          <span>{item.nama}</span>
                          <IoDownloadOutline />
                        </div>
                      </a>
                    </>
                  );
                })}
            </div>}
            {user && <hr />}
            <div class="comment-contents">
              <h3>Komentar</h3>
              <p>Berkomentarlah dengan bijak</p>
              {user !== undefined && user !== null ? (
                <>
                  <div class="comment-form">
                    <img src={profile_pic} class="author-img" />
                    <div className="w-100">
                      <input
                        type="text"
                        value={txt}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            postComment(txt);

                            setTxt("");
                          }
                        }}
                        onChange={(e) => setTxt(e.target.value)}
                        class="form-control"
                        placeholder="Tulis komentarmu di sini"
                        name=""
                      />
                    </div>
                  </div>

                  {dataComment.length > 0 &&
                    dataComment.map((item, index) => {
                      return (
                        <div class="comment-reply">
                          <img src={item.profile_pic} class="author-img" />
                          <div class="comment-text">
                            <div class="comment-author">{item.nama}</div>
                            <div class="comment-detail">{item.txt}</div>

                            {replyID !== null && replyID === item.id ? (
                              // {replyID !== null && replyID === item.id ? (
                              <div class="comment-form">
                                <img src={profile_pic} class="author-img" />
                                <form>
                                  <input
                                    type="text"
                                    value={txt}
                                    onChange={(e) => setTxt(e.target.value)}
                                    class="form-control"
                                    style={{
                                      width: "200%",
                                    }}
                                    placeholder="Tulis reply di sini"
                                    name=""
                                  />
                                </form>
                              </div>
                            ) : (
                              <a
                                onClick={() => {
                                  handleChangeReplyid(item.id);
                                }}
                                // onClick={() => {
                                //   handleChangeReplyid(item.id);
                                // }}
                                class={"reply-btn"}>
                                Balas
                              </a>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </>
              ) : (
                <div class="comment-form">
                  {/* <img src={profile_pic} class="author-img" /> */}
                  <a href="/login" className="w-100">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Tulis komentarmu di sini"
                      name=""
                    />
                  </a>
                </div>
              )}
            </div>
            <hr />
            <div class="articles-wrapper">
              <div class="heading-blocks">
                <h3>Rekomendasi</h3>
              </div>
              <div class="row">
                {related.map((item, idx) => {
                  return (
                    <div class="col-md-3 col-6" key={idx}>
                      <div class="video-article-item">
                        <a href={`/detail_inovasi/${item.id}`}>
                          <img
                            src={
                              item.has_video === 1
                                ? item.indikator_video.thumbnail_url
                                : "https://api.tuxedovation.com/file/novideo.jpg"
                            }
                            class="thumbnail-img"
                          />
                          <h4 class="meta-title">{item.nama}</h4>
                        </a>
                        <a href="#" class="meta-location">
                          {item.pemda}
                        </a>
                        <div class="meta-views">
                          {/* <IoEyeSharp /> {item.views || 0} views -{" "} */}
                          {item.created_at}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  /*
	return <div class="content">
				<div class="container">
					<div class="blog-list-wrapper">
						<div class="row">
							<div class="col-sm-8">
								<div class="main-post-content">
								{loading && <Loader
									type="Puff"
									color="#00BFFF"
									height={100}
									width={100}
									timeout={3000} //3 secs
									/>}
									{data && <div class="blog-post m-b-30">
										{data.has_video === 1 && <div class="post-image">
											<video
											    id="my-video"
											    class="video-js"
											    controls
											    preload="auto"
											    width="640"
											    data-setup="{}"
											    >
											    <source src={video.file_url} type="video/mp4" />
											    <p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>
											</video>
											{category.map((item, index)=>{
												return <span key={index} class="label category-label" onClick={()=>{
													document.location="/category/" + item.urusan_pemerintah_id
												}}>{item.nama}</span>
											})}
										</div>}
										<div class="text-muted"><span>Dipublikasikan oleh Pemerintahan Daerah : <a class="text-dark font-secondary">{data.pemda}</a>,</span> <span>Pada Tanggal: {data.created_at}</span></div>
										<div class="text-muted"><span>Telah dilihat {accounting.formatNumber(data.total_views,'.',0)} kali</span></div>
										<div class="post-title">
											<h1>{data.nama}</h1>
										</div>
										<div class="post-main-content">
											<h3>Latar Belakang</h3>
											<div dangerouslySetInnerHTML={{__html:data.latar_belakang}}></div>
											<hr/>
											<h3>Tujuan</h3>
											<div dangerouslySetInnerHTML={{__html:data.tujuan}}></div>
											<hr/>
											<h3>Manfaat</h3>
											<div dangerouslySetInnerHTML={{__html:data.manfaat}}></div>
											<hr/>
										</div>
										<div class="post-main-content">
											<h4>Bagikan: </h4>
											<div className={`sns-icons`}>
												<FacebookShareButton
											            url={shareUrl}
											       		className={`sns-icons`}
											          >
											            <FacebookIcon size={32} round />
											          </FacebookShareButton>
											     <TwitterShareButton
											            url={shareUrl}
											       	className={`sns-icons`}
											          >
											            <TwitterIcon size={32} round />
											          </TwitterShareButton>
											           <LinkedinShareButton
											            url={shareUrl}
											       	className={`sns-icons`}
											          >
											            <LinkedinIcon size={32} round />
											          </LinkedinShareButton>
											     <WhatsappShareButton
											            url={shareUrl}
											       className={`sns-icons`}
											          >
											            <WhatsappIcon size={32} round />
											          </WhatsappShareButton>
											</div>
										</div>
									</div>}
								</div>
							</div>
							<div class="col-sm-4">
								<Related data={related}/>
							</div>
						</div>
					</div>
				</div>
			</div>
			*/
}
