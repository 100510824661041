import React, { useEffect, useState } from "react";
import { attachSignedInStyle } from "../../libs/helper";
import { Header } from "../../parts";
import LabelAndImagePicker from "../../widgets/LabelAndImagePicker";
const Profile = (props) => {
  const { callAction, call_action, match } = props;
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));
  const daerah = JSON.parse(localStorage.getItem("daerah"));
  const [fileurl, setFileURL] = useState("");

  const handleContent = (payload) => {
    console.log("upload_photo", payload.upload_photo);
    if (typeof payload.upload_photo === "undefined") return;
    if (payload.upload_photo === null) return;
  };

  const postPhoto = () => {
    callAction(call_action, "upload_photo", {
      endpoint: `/profile`,
      scenario: "post",
      data: {
        file_url: fileurl,
      },
    });
    setLoading(true);
  };

  useEffect(() => {
    if (loading && call_action.upload_photo !== null) {
      handleContent(call_action.upload_photo);
      setLoading(false);
    }
  }, [call_action.upload_photo]);

  useEffect(() => {
    attachSignedInStyle();
  }, []);
  return (
    <>
      <Header place="content" />
      <section className="content">
        <div className="content-wrap">
          <div className="section section-categories nobottommargin dark">
            <div className="container">
              <div className="articles-wrapper">
                <div class="heading-blocks">
                  <h3>Profile</h3>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-4">
                    <div className="card border-white">
                      <div
                        style={{
                          boxShadow: "4px 2px #fff",
                        }}
                        className="card-body d-flex flex-column justify-content-center align-items-center">
                        <img
                          alt="profile-pic"
                          width={200}
                          className="rounded-circle mx-auto mb-3"
                          src={user.profile_pic}
                        />
                        <span
                          style={{
                            fontSize: 18,
                            textAlign: "center",
                            fontWeight: "bold",
                            marginBottom: 10,
                          }}>
                          {user.name}
                        </span>
                        <span
                          style={{
                            fontSize: 16,
                            textAlign: "center",
                            color: "gray",
                            marginBottom: 10,
                          }}>
                          {" "}
                          {daerah.nama}
                        </span>
                        <LabelAndImagePicker onChange={setFileURL} />
                        <button
                          onClick={() => postPhoto()}
                          className="w-75 btn btn-warning">
                          Ubah Foto Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;
