/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";

import { Header, Sidebar, ContentItem, Footer, ProfilVideo } from "../../parts";

const Home = (props) => {
  const { callAction, call_action } = props;
  const [recent, setRecent] = useState([]);
  const [profil, setProfil] = useState(null);
  const [recommended, setRecommended] = useState([]);
  const [myvideos, setMyVideos] = useState([]);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [daerah_id, setDaerah] = useState(0);
  const [categories, setCategories] = useState([]);

  let user = localStorage.getItem("user");

  const handleContent = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setProfil(payload.profil ? payload.profil : null);
    setRecent(payload.recent ? payload.recent : []);
    setRecommended(payload.recommended ? payload.recommended : []);
    setMyVideos(payload.myvideo ? payload.myvideo : []);
    setUpdate(update + 1);
    console.log("done");
  };

  useEffect(() => {
    let mdid = parseInt(localStorage.getItem("mdid"));
    if (user === null || user === undefined) {
      window.location.replace("/");
    }
    callAction(call_action, "home", {
      endpoint: "/tuxe/home",
    });
    setLoading(true);
    setDaerah(mdid);
    let c = localStorage.getItem('categories');
    if(c){
      setCategories(JSON.parse(c));
    }
  }, []);

  useEffect(() => {
    if (
      loading &&
      typeof call_action.home !== "undefined" &&
      call_action.home !== null
    ) {
      console.log("loading done", call_action.home);

      handleContent(call_action.home);
      setLoading(false);
    }
  }, [call_action.home]);

  return (
    <>
      <Header place="content" />
      <Sidebar {...props} />
      <div className="content-page">
        <div className="content">
          <div className="container">
            {profil && <h3 className="section-title">Profil Kemendagri/BPP</h3>}
            {profil && <ProfilVideo data={profil} />}
            {daerah_id > 0 && <h3 className="section-title">My Videos</h3>}
            {daerah_id > 0 && <ContentItem loading={loading} data={myvideos} />}
            <h3 className="section-title">Recommended</h3>
            {recommended && (
              <ContentItem loading={loading} data={recommended} />
            )}
            <hr className="section-separator" />
            <h3 className="section-title">Recent Updates</h3>
            {recent && <ContentItem loading={loading} data={recent} />}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
