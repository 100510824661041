import CreateStore from "./CreateStore";

const CrudStore = (storeName, endpoint) => {
  const [retrieveReducer, retrieveAction] = CreateStore(
    storeName,
    endpoint,
    "pagination"
  );
  const [readReducer, readAction] = CreateStore(
    storeName + "_READ",
    endpoint,
    "read"
  );
  const [createReducer, createAction] = CreateStore(
    storeName + "_CREATE",
    endpoint,

    "insert"
  );
  const [updateReducer, updateAction] = CreateStore(
    storeName + "_UPDATE",
    endpoint,

    "update"
  );
  const [removeReducer, removeAction] = CreateStore(
    storeName + "_DELETE",
    endpoint,

    "delete"
  );

  const reducers = {};
  const actions = {};

  reducers[storeName + "_retrieve"] = retrieveReducer;
  reducers[storeName + "_read"] = retrieveReducer;
  reducers[storeName + "_create"] = createReducer;
  reducers[storeName + "_update"] = updateReducer;
  reducers[storeName + "_delete"] = removeReducer;

  actions[storeName + "Action"] = retrieveReducer;
  actions[storeName + "ReadAction"] = retrieveReducer;
  actions[storeName + "CreateAction"] = createReducer;
  actions[storeName + "UpdateAction"] = updateReducer;
  actions[storeName + "DeleteAction"] = removeReducer;

  return {
    reducers,
    actions
  };
};
export default CrudStore;
