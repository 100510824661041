import Api from "../../Services/Api";

const api = new Api();

//this would be a single action but can be called to many components
//so the payload must be splitted by the component's name
export default (state, name, opts) => (dispatch) => {
  let busy = false;
  let q = [];

  const queue = ({ state, name, opts }) => {
    q.push({ state, name, opts });
  };

  const proceed = () => {
    if (q.length === 0) {
      busy = false;
      return;
    }
    busy = true;
    const { state, name, opts } = q.shift();
    let _state = {
      ...state,
      type: "CALL_ACTION",
      data: opts,
      fetching: true,
    };
    _state[name] = null;
    dispatch(_state);

    api.call(opts, true).then((response) => {
      if (response.status === 1) {
        let new_state = {
          ...state,
          type: "CALL_ACTION_SUCCESS",
        };
        new_state[name] = response;
        dispatch(new_state);
      } else {
        if (
          typeof response.error !== "undefined" &&
          response.error === "access_denied"
        ) {
          localStorage.removeItem("token");
          document.location = "/login";
        }
        let new_state = {
          ...state,
          type: "CALL_ACTION_ERROR",
        };
        new_state[name] = response;
        dispatch(new_state);
      }
      proceed();
    });
  };

  queue({ state, name, opts });

  if (!busy) proceed();
};
