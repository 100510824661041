import React, {
	useState,
	useEffect
} from "react";

import {
	Header,
	Sidebar,
	ContentItem,
	Footer
} from "../../parts";

import Loader from "react-loader-spinner";


const Logout = (props) => {
	const {
		callAction,
		call_action,
		match
	} = props;

	useEffect(()=>{
		localStorage.clear();
		document.location="/";
	},[]);

	

	return <></>
	
};

export default Logout;