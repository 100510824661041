import React, { useState, useEffect, useCallback } from "react";

import { Header, Sidebar, ContentItem, Footer } from "../../parts";
import {
  resetStyle,
  attachLandingStyle,
  attachSignedInStyle,
} from "../../libs/helper";
import Content from "./Content";

const Detail = (props) => {
  const { callAction, call_action, match } = props;

  const [data, setData] = useState(null);
  const [category, setCategory] = useState([]);
  const [related, setRelated] = useState([]);
  const [video, setVideo] = useState(null);
  const [update, setUpdate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingComment, setLoadingComment] = useState(false);
  const [dokumen, setDokumen] = useState([]);
  const [dataComment, setDataComment] = useState([]);
  const [offset, setOffset] = useState(0);
  const [nextOffset, setNextOffset] = useState(0);

  const handleContent = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;

    setData(payload.inovasi);
    setVideo(payload.inovasi.indikator_video);
    setCategory(payload.categories);
    setDokumen(payload.documents);
    setRelated(payload.related);
    setUpdate(update + 1);
  };

  const handleComment = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setDataComment([...dataComment, ...payload.data] || []);
    setNextOffset(parseInt(payload.next_offset) || 0);
  };

  const postComment = (txt) => {
    callAction(call_action, "post_comments", {
      endpoint: `/tuxe/${match.params.id}/comments`,
      scenario: "post",
      data: {
        txt: txt,
      },
    });
     setOffset(0);
  };
  const postLike = () => {
    callAction(call_action, "post_like", {
      endpoint: `/tuxe/${match.params.id}/like`,
      scenario: "get",
    });
  };
  const postUnlike = () => {
    callAction(call_action, "post_unlike", {
      endpoint: `/tuxe/${match.params.id}/unlike`,
      scenario: "get",
    });
  };

  const Mutate = useCallback(() => {
    setUpdate((prev) => prev + 1);
  }, []);

  const handleCommentMore = useCallback(() => {
    setOffset(nextOffset);
  }, []);

  const fetchComment = (_offset) => {
   

    callAction(call_action, "get_comments", {
      endpoint: `/tuxe/${match.params.id}/comments`,
      data: {
        offset: typeof _offset !== 'undefined' ? _offset : nextOffset,
      },
    });
    if(typeof _offset !== 'undefined'){
        setDataComment([]);
    }
    setLoadingComment(true);
  };

  useEffect(() => {

    fetchComment(offset);

  }, [offset]);

  useEffect(() => {
    if (loadingComment && call_action.get_comments) {
      handleComment(call_action.get_comments);
      setLoadingComment(false);
    }
  }, [call_action.get_comments]);

  useEffect(() => {
    if (call_action.post_comments) {
      fetchComment(0);
    }
  }, [call_action.post_comments]);

  useEffect(() => {
    resetStyle();
    attachSignedInStyle();
    callAction(call_action, "detail", {
      endpoint: `/tuxe/inovasi/detail/${match.params.id}`,
    });
    setLoading(true);
  }, []);

  useEffect(() => {
    if (loading && call_action.detail !== null) {
      handleContent(call_action.detail);
      setLoading(false);
    }
  }, [call_action.detail]);


  return (
    <>
      <Header place="content" />
      <section id="content" style={{ backgroundColor: "#000000" }}>
        <Content
          data={data}
          dataComment={dataComment}
          postComment={postComment}
          postLike={postLike}
          postUnlike={postUnlike}
          loadMoreComment={handleCommentMore}
          loadingComment={loadingComment}
          Mutate={Mutate}
          loading={loading}
          video={video}
          Dokumen={dokumen}
          category={category}
          related={related}
        />
      </section>
      <Footer />
    </>
  );
};

export default Detail;
