import React, {
	useState,
	useEffect
} from "react";

import {
	Header,
	Sidebar,
	ContentItem,
	Footer
} from "../../parts";
import Loader from "react-loader-spinner";
import { VectorMap } from "@react-jvectormap/core";
import MapIndo from "./MapIndo.json";
import {resetStyle, attachLandingStyle, attachSignedInStyle} from "../../libs/helper";

const Map = (props) => {
	const {
		callAction,
		call_action,
		match
	} = props;
	const [province_id,setProvinceId] = useState(0);
	const [data, setData] = useState(null);
	const [category, setCategory] = useState([]);
	const [related, setRelated] = useState([]);
	const [video, setVideo] = useState(null);
	const [update, setUpdate] = useState(0);
	const [loading, setLoading] = useState(false);

	const load = (province_id) => {
		callAction(call_action,'map_data',{
			endpoint:`/tuxe/inovasi-per-propinsi/${province_id}`
		});
		setLoading(true);
	}
	const handleContent = (payload) => {
		
		if(!payload) return;
		setData(payload.data ? payload.data : []);
	}
	useEffect(()=>{
		resetStyle();
		attachSignedInStyle();
	},[])
	useEffect(()=>{
		if(province_id)
			load(province_id);
	},[province_id]);

	useEffect(()=>{
		if(loading && call_action.map_data !== null){
			console.log("handleContent");
			handleContent(call_action.map_data);
			setLoading(false);
		}
		
	},[call_action.map_data]);


	return <>
		<Header place="content"/>
		<section id="content">
			<div class="content-wrap">
				<div class="section section-categories nobottommargin dark">
					<div className = "container" >
	
			 <div style={{width: '500', height: 350}}>
            <VectorMap
			        map={MapIndo}
			        backgroundColor="#e5e5e5"
			         regionsSelectable={true}
            		regionsSelectableOne={true}
			        containerStyle={{
			          width: '100%',
			          height: '100%',
			        }}
			        regionStyle={{
			                initial: {
			                    fill: '#999',		
			                },
			                selected: {
			                    fill: 'red'
			                },
			               
			            }}
			        onRegionClick={(evt,code)=>{
			        		console.log({code});
			        }}
			        onRegionSelected={(e,code,isSelected)=>{
			        	setProvinceId(code)
			        }}
			        containerClassName="map"
			      />

        </div>
        <div class="row">
        <div class="col-md-12 pb-2 pt-2">
       {loading && <Loader
       	style={{margin:'0 auto', textAlign:'center'}}
						type="Puff"
						color="#00BFFF"
						height={60}
						width={60}
						timeout={3000} //3 secs
						/>}
        {data && <table className={`table table-condensed`}>
        	<thead>
        		<th>
        			No. 
        		</th>
        		<th>
        			Pemda 
        		</th>
        		<th>
        			Jumlah Inovasi 
        		</th>
        		<th>
        			Jumlah Video
        		</th>
        		
        	</thead>
        	<tbody>
    	{data && data.map((item,index)=>{
        		return (<tr key={index}>
        			<td>{index + 1}</td>
        			<td>{item.pemda.nama}</td>
        			<td>{item.total_inovasi}</td>
        			<td>{item.total_video ? item.total_video.total : 0}</td>
        		</tr>);
        	})}
        		
        	</tbody>
        </table>}
        
        </div>
        </div>
				</div>
				</div>
			</div>
		</section>


		<Footer/>
		</>
	
};

export default Map;