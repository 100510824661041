import React, { useState, useEffect, useCallback } from "react";

import { Header, Sidebar, ContentItem, Footer } from "../../parts";
import {
  IoShareSocialSharp,
  IoCloudDownloadSharp,
  IoDownloadOutline,
  IoEyeSharp,
  IoLocationSharp,
  IoCalendarNumberSharp,
} from "react-icons/io5";
import {
  resetStyle,
  attachLandingStyle,
  attachSignedInStyle,
} from "../../libs/helper";

const Popular = (props) => {
  const { callAction, call_action, match } = props;
  const [data, setData] = useState([]);
  const [category, setCategory] = useState(null);
  const [offset, setOffset] = useState(0);
  const [loading, setLoading] = useState(false);
  const [nextOffset, setNextOffset] = useState(0);

  const handleContent = (payload) => {
    if (typeof payload === "undefined") return;
    if (payload === null) return;
    setData([...data, ...payload.inovasi] || []);
    setCategory(payload.category || null);
    setNextOffset(payload.next_offset || 0);
  };

  const callApi = () => {
    callAction(call_action, "popular", {
      endpoint: `/tuxe/popular`,
      data: {
        offset: offset,
        klaster: 0,
        covid: 0,
      },
    });
  };

  const handleLoadMore = useCallback((nextOffset) => {
    setOffset(nextOffset);
  }, []);

  useEffect(() => {
    callApi();
    setLoading(true);
  }, [offset]);

  useEffect(() => {
    resetStyle();
    attachSignedInStyle();
    setLoading(true);
  }, []);

  useEffect(() => {
    if (loading && call_action.popular !== null) {
      handleContent(call_action.popular);
      setLoading(false);
    }
  }, [call_action.popular]);

  return (
    <>
      <Header place="content" />
      <section id="content">
        <div class="content-wrap">
          <div class="section section-categories nobottommargin dark">
            <div class="container">
              <div class="articles-wrapper">
                <div class="heading-blocks">
                  <h3>Video Terpopuler</h3>
                </div>
                {data.length > 0 && (
                  <div class="row">
                    {data.map((item, idx) => {
                      return (
                        <div key={idx} class="col-md-3 col-6">
                          <div class="video-article-item">
                            <a href={`/detail_inovasi/${item.id}`}>
                              <img
                                src={
                                  item.has_video === 1
                                    ? item.indikator_video.thumbnail_url
                                    : "https://api.tuxedovation.com/file/novideo.jpg"
                                }
                                class="thumbnail-img"
                              />
                              <h4 class="meta-title">{item.nama}</h4>
                            </a>
                            <a href="#" class="meta-location">
                              {item.pemda}
                            </a>
                            <div class="meta-views">
                              {/* <IoEyeSharp /> {item.views || 0} views -{" "} */}
                              {item.created_at}
                            </div>
                          </div>
                        </div>
                      );
                    })}

                    <div class="col-md-12 center">
                      <button
                        onClick={() => handleLoadMore(nextOffset)}
                        // href="#"
                        class="button load-more-button">
                        {loading ? "Loading..." : "Load More"}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Popular;
