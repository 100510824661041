import React from "react";

export default function(props){
	const {data} = props;
	return <div className="row equal-height">
              	<div className="col-md-12">
			                <div className="blog-post blog-post-column m-b-20">
			                  <div className="post-image">
			                    <video
											    id="my-video"
											    class="video-js"
											    controls
											    preload="auto"
											    width="640"
											    data-setup="{}"
											    >
											    <source src={data.content} type="video/mp4" />
											    <p class="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>
											</video>
			                  </div>
			                  
			                </div>
			              </div>
             
            </div>
}