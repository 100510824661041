import React, { useState, useEffect } from "react";

const Sidebar = (props) => {
  const { callAction, call_action } = props;
  const [categories, setCategories] = useState([]);
  const [choosed, setChoosed] = useState("");
  const [update, setUpdate] = useState(0);

  useEffect(() => {
    try {
      let cat = JSON.parse(localStorage.getItem("categories")) || [];

      console.log({ cat });
      if (cat.length === 0) {
        callAction(call_action, "categories", {
          endpoint: "/tuxe/categories",
        });
      } else {
        setCategories(cat);
      }
    } catch (err) {}
  }, []);

  useEffect(() => {
    if (call_action.categories) {
      localStorage.setItem(
        "categories",
        call_action.categories
          ? JSON.stringify(call_action.categories.categories)
          : null,
      );
      let cat = call_action.categories.categories || [];

      setCategories(cat);
      setUpdate(update + 1);
    }
  }, [call_action.categories]);

  return (
    <div className="left side-menu">
      <div className="sidebar-inner slimscrollleft">
        <div id="sidebar-menu">
          <ul>
            <li>
              <a href="/" className="waves-effect">
                <i className="mdi mdi-home"></i> <span>Home</span>
              </a>
            </li>
            <li className="has_sub">
              <a href="javascript:void(0);" className="waves-effect">
                <i className="mdi mdi-view-list"></i> <span>Category</span>{" "}
                <span className="menu-arrow"></span>
              </a>
              <ul className="list-unstyled">
                {categories.length > 0 &&
                  categories.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={`/category/${item.id}`}>{item.nama}</a>
                      </li>
                    );
                  })}
              </ul>
            </li>
            <li>
              <a href="/popular" className="waves-effect">
                <i className="mdi mdi-star"></i> <span>Popular</span>
              </a>
            </li>
            <li>
              <a href="/new_release" className="waves-effect">
                <i className="mdi mdi-new-box"></i> <span>New Release</span>
              </a>
            </li>
            {/*<li>
								<a href="/pamong" className="waves-effect">
									<i className="mdi mdi-playlist-check"></i> <span>Pamong</span>
								</a>
							</li>*/}
            <li>
              <a href="/map" className="waves-effect">
                <i className="mdi mdi-map"></i> <span>Map</span>
              </a>
            </li>
            <li>
              <a href="/about-us" className="waves-effect">
                <i className="mdi mdi-domain"></i> <span>About us</span>
              </a>
            </li>
            <li>
              <a href="/faq" className="waves-effect">
                <i className="mdi mdi-comment-question-outline"></i>{" "}
                <span>FAQ</span>
              </a>
            </li>
          </ul>
        </div>
        <div className="clearfix"></div>
        <div className="help-box">
          <h5 className="text-muted m-t-0">Pusdatin</h5>
          <p className="">
            <span className="text-custom">Alamat:</span> <br /> Jl. Medan
            Merdeka Utara No. 7, Jakarta Pusat
          </p>
          <p className="">
            <span className="text-custom">Telp:</span> <br /> (021) 3450038
          </p>
          <p className="">
            <span className="text-custom">Fax:</span> <br /> (021) 3851193,
            34830261, 3846430
          </p>
          <p className="">
            <span className="text-custom">Email:</span> <br />{" "}
            <a href="mailto:pusdatin@kemendagri.go.id?subject=KONTAK%20WEBSITE%20KEMENDAGRI">
              pusdatin@kemendagri.go.id
            </a>
          </p>
          <p className="social-wrap m-b-0">
            <span className="text-custom">Temukan Kami di:</span> <br />{" "}
            <a
              href="https://www.facebook.com/Kemendagri/"
              target="_blank"
              rel="noreferrer">
              <i className="fa fa-facebook"></i>
            </a>
            <a href="https://twitter.com/Kemendagri_RI">
              <i className="fa fa-twitter"></i>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
