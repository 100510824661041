import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import ImageUploader from "react-images-upload";
import Api from "../Services/Api";
const api = new Api();

export default function LabelAndImagePicker(props) {
  const { label, onChange, value, action } = props;
  const [progress, setProgress] = useState(false);
  const [imgurl, setImageUrl] = useState("");

  useEffect(() => {
    if (typeof value === "undefined") return;
    if (value === null) return;
    setImageUrl(value);
  }, [value]);

  if (progress) {
    return (
      <div
        className={`form-control`}
        style={{
          marginBottom: "10px",
          ...props.containerStyle,
        }}>
        <div style={{ textAlign: "center" }}>
          <Loader type="Puff" color="#00BFFF" height={100} width={100} />
        </div>
        Upload in progress...
      </div>
    );
  }
  return (
    <div
      className={`form-control`}
      style={{
        marginBottom: "10px",
        ...props.containerStyle,
      }}>
      <div
        style={{
          fontWeight: "bold",
          ...props.labelStyle,
        }}>
        {label}
      </div>
      <div>
        {imgurl !== "" ? (
          <div style={{ textAlign: "center" }}>
            <img src={imgurl} style={{ height: 120 }} />
          </div>
        ) : null}
        {imgurl === null && value !== null ? (
          <div style={{ textAlign: "center" }}>
            <img src={value} style={{ height: 120 }} />
          </div>
        ) : null}
        <ImageUploader
          withPreview={true}
          withIcon={true}
          singleImage={true}
          buttonText="Choose images"
          onChange={(picture) => {
            setProgress(true);
            api
              .upload(
                "/media/upload",
                {
                  file: picture[0],
                },
                true,
              )
              .then((response) => {
                setProgress(false);
                setImageUrl(response.file_url);
                onChange(response.file_url, response.file);
              })
              .catch((err) => {
                setProgress(false);
                console.log(err.message);
              });
          }}
          imgExtension={[".jpg", ".gif", ".png", ".gif", ".svg", ".jpeg"]}
          maxFileSize={52428800}
        />
      </div>
    </div>
  );
}
